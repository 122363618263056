.ryan-photo {
  margin-left: 10vw;
  margin-top: 3vw;
  display: block;
  height: 30%;
  width: 30%;
  float: left;
  background: transparent;
}

.home-div {
  background: #13B5EA;
}

.ryan-intro-text {
  margin-top: 25vh;
  width: 50%;
  float: right;
  font-weight: bold;
  font-family: 'Lexend Deca', sans-serif;
}

.ryan-intro-background {
  font-family: 'Atkinson Hyperlegible', sans-serif;
}

h4 {
  font-weight: normal;
}
