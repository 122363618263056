.main-nav {
  text-align: left;
  overflow: auto;
}

.left-side {
  float: left;
  text-align: left;
  margin-left: 10em;
  margin-right: 10em;
}

.right-side {
  float: right;
  margin-right: 10em;
  margin-top: 1em;
}

.nav-link {
  font-family: 'Zen Antique Soft', serif;
  font-size: 9;
  font-weight: 500;
  color: black;
  text-decoration: none;
  display: inline-block;
  padding: 2vw 4vw 2vw 4vw;
}

.nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #13B5EA;
}

.nav-link:hover::after {
  width: 100%;
  transition: width .3s;
}

a {
  color: inherit;
  text-decoration: none;
}